import axios from 'axios';
import { AbuserDto, AccountSetupInfoDto, CreateManualOrderDto, DashboardAbuserDto, DashboardFailedWorkflowDto, DashboardWorkflowDto, OrderProgressDto, ReassignAbuserNamesDto, UpdateAccountBaseInfoDto, UpdateAccountRecoveryInfoDto, SyncPartialAccountInfoDto, SyncAccountPasswordDto, SyncAccountSessionDto, RefillOrderTasksDto, ProcessDailyFreePromotionsDto, AsCandidateDto, CreateSubscriptionDto, PublishSubscriptionActionDto, CreateSubscriptionMemoDto, ChangeSubscriptionDto, OrderTaskPriorityType, GuestOrderProgressDto, MagicValueType, MagicValues, SubscriptionDto, CreatePackageSubscriptionDto, CreateOrderMemoDto, ShopOrderResponse, DashboardDailyPromotionDto, TemporaryMembershipDto, SendMembershipStartDto, DepositPageDto, CreateDepositDto, UrgentSubscriptionDto, AutopilotParameter, CreateFlashNotificationDto, UpdateFlashNotificationDto, FlashNotificationDto, TempAutoLikeCustomerDto, CreateTempAutoLikeCustomerDto, DashboardSubscriptionDto, ChangeTempAutoLikeCustomerDto, ChangeOrderTaskProgressCountDto, ChangeReservedDtDto, ExternalServiceJobDto, CreateManualPromotionDto, CreateDailyPromotionAbuserDto, DailyPromotionAbuserDto, CreateStaffDto, StaffDto, ChangeStaffPasswordDto, GetBannedAbuserDto, SubscriptionOrderDto, AsHistoryDto, CreateAsHistoryDto, AddNewIgIdDto, AsHistoryMapAsHistoryMap, CustomerDto, ReassignCustomerPasswordDto, UpdateCustomerDto, ResponseLoginCustomerDto, ExtendTempAutoLikeCustomerDto } from './apiTypes';
import axiosInstance from './axiosInstance';

class Repository {
    url = '/insta-leader';

    async getUnprogressedList(): Promise<Array<AccountSetupInfoDto>> {
        const response = await axiosInstance.get(`${this.url}/abusers/unprogressed-list?limit=100000000`);
        const total = response.data?.length;
        // @ts-ignore
        const a = (response.data ?? []).filter(d => d.isProgressedDoneImages).length;
        // @ts-ignore
        const b = (response.data ?? []).filter(d => d.isProgressedProfile).length;
        // @ts-ignore
        const c = (response.data ?? []).filter(d => d.isProgressedUsername).length;
        // @ts-ignore
        const d = (response.data ?? []).filter(d => d.isProgressedPrivate).length;
        // @ts-ignore
        const e = (response.data ?? []).filter(d => d.isProgressedBasename).length;
        // @ts-ignore
        const f = (response.data ?? []).filter(d => d.isProgressedDesc).length;
        // @ts-ignore
        const g = (response.data ?? []).filter(d => d.isProgressedDeletePost).length;

        console.log(`
            total count: ${total}
            게시물 업로드 -> [${a}/${total}]
            프로필 이미지 -> [${b}/${total}]
            닉네임 -> [${c}/${total}]
            비공개처리 -> [${d}/${total}]
            기본이름 -> [${e}/${total}]
            자기소개 -> [${f}/${total}]
            첫 포스트 삭제 -> [${g}/${total}]
        `)
        return response.data ?? [];
    };


    async getRecentBannedAbuserList(): Promise<Array<AbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/all-recent-banned-abuser-lists`);
        return response.data ?? [];
    };

    async getUploadPostList(): Promise<Array<AccountSetupInfoDto>> {
        const response = await axiosInstance.get(`${this.url}/abusers/to-upload-photos-list?limit=100000000`);
        const total = response.data?.length;
        // @ts-ignore
        const a = (response.data ?? []).filter(d => d.isProgressedDoneImages).length;

        console.log(`
            total count: ${total}
            게시물 업로드 -> [${a}/${total}]
        `)
        return response.data ?? [];
    };

    async getAccountList(id?: string, createdAt?: string, status: string = 'none'): Promise<Array<DashboardAbuserDto>> {
        if (!id || !createdAt) {
            const response = await axiosInstance.get(`${this.url}/dashboard/all-active-abuser-lists?status=${status}`);
            return response.data ?? [];
        }

        const response = await axiosInstance.get(`${this.url}/dashboard/all-active-abuser-lists?id=${id}&createdAt=${createdAt}&status=${status}`);
        return response.data ?? [];
    };

    async getLockedList(): Promise<Array<AbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/abusers/locked-list?limit=10000000`);
        return response.data ?? [];
    };

    async getFinishedAbusers(): Promise<Array<AbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/setup-manager/finished-list`);
        return response.data ?? [];
    };

    async getNeedSessionList(): Promise<Array<AbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/abusers/need-session-list`);
        return response.data ?? [];
    };

    async getOrderList(): Promise<Array<OrderProgressDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/order-progresses`);
        return response.data ?? [];
    };

    async getCanceledOrderList(): Promise<Array<OrderProgressDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/canceled-order-progresses`);
        return response.data ?? [];
    };

    // 대상 가계정의 팔로우를 증가시킨다 (최대 30개)
    async addFollowerForAbuser(abuserId: string, count: number) {
        const response = await axiosInstance.post(`${this.url}/dashboard/abusers/${abuserId}/follow?count=${count}`);
        return response.status;
    };

    async changeMaxAssignCount(orderTaskId: string, maxAssignPerCycleCnt: number) {
        const response = await axiosInstance.post(`${this.url}/order-tasks/${orderTaskId}/max-assign-count`, { maxAssignPerCycleCnt });
        return response.status;
    };

    async getOrderProgress(orderId: string): Promise<GuestOrderProgressDto> {
        const response = await axiosInstance.get(`${this.url}/guests/order-progress/${orderId}`);
        return response.data;
    };

    async getAllWorkflows(startDate: string, endDate: string): Promise<Array<DashboardWorkflowDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/all-done-workflows?start-date=${startDate}&end-date=${endDate}`);
        return response.data ?? [];
    };

    async getRecentOrderProgress(): Promise<Array<OrderProgressDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/recent-order-progresses`);
        return response.data;
    };

    async getFailedWorkflows(startDate: string, endDate: string): Promise<Array<DashboardFailedWorkflowDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/failed-workflows?start-date=${startDate}&end-date=${endDate}`);
        return response.data ?? [];
    };

    async getLostAbusers(targetId: string): Promise<Array<AbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/lost-abusers?targetId=${targetId}`);
        return response.data ?? [];
    };

    async getOrderTask(orderTaskId: string): Promise<OrderProgressDto> {
        const response = await axiosInstance.get(`${this.url}/dashboard/order-progresses/${orderTaskId}`);
        return response.data;
    };

    async pauseOrderTask(orderTaskId: string) {
        return await axiosInstance.post(`${this.url}/order-tasks/${orderTaskId}/pause`);
    };

    async resumeOrderTask(orderTaskId: string) {
        return await axiosInstance.post(`${this.url}/order-tasks/${orderTaskId}/resume`);
    };

    async login(email: string, password: string) {
        const response = await axiosInstance.post(`${this.url}/staff/login`, { email, password });
        return response.data;
    };

    async reasignAbuserProfile(abuserId: string) {
        const response = await axiosInstance.post(`${this.url}/abusers/reassign-abuser-profile/${abuserId}`);
        return response.data;
    };

    async getWorkableQuota(): Promise<number> {
        const response = await axiosInstance.get(`${this.url}/dashboard/workable-quota`);
        return response.data;
    };

    async getCanSchedule(): Promise<boolean> {
        const response = await axiosInstance.get(`${this.url}/schedules/can-schedule`);
        // console.log('res', response);
        return response.data;
    };

    async activateSchedule() {
        const response = await axiosInstance.post(`${this.url}/schedules/activate`);
        return response.data.value === true;
    };

    async deactivateSchedule() {
        const response = await axiosInstance.post(`${this.url}/schedules/deactivate`);
        return response.data.value === false;
    };

    async resetUserName(abuserIds: Array<string>) {
        const response = await axiosInstance.post(`${this.url}/abusers/reassign-abuser-names`, { abuserIds });
        return response.status === 201;
    };

    async deleteOrder(orderId: string) {
        const response = await axiosInstance.delete(`${this.url}/orders/${orderId}`);
        return response.data;
    };

    async postManualOrder(order: CreateManualOrderDto) {
        const response = await axiosInstance.post(`${this.url}/orders/manual`, order);
        return response.data;
    };

    async getAbuserDetail(abuserId: string) {
        const response = await axiosInstance.get(`${this.url}/abusers/${abuserId}`);
        return response.data;
    };

    async updateAbuser(abuserId: string, data: UpdateAccountBaseInfoDto) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/base_info`, data);
        return response.status === 200;
    };

    async lockPersonPhoto(photoId: string) {
        const response = await axiosInstance.post(`${this.url}/person-photos/${photoId}/lock`);
        return response.data;
    };

    async unlockPersonPhoto(photoId: string) {
        const response = await axiosInstance.post(`${this.url}/person-photos/${photoId}/unlock`);
        return response.data;
    };

    async updateRecoveryByEmail(emails: UpdateAccountRecoveryInfoDto){
        const response = await axiosInstance.put(`${this.url}/abusers/update-recovery-by-email`, emails);
        return response.status === 200;
    };

    async updateAbuserSettingInfo(abuserId: string, body: SyncPartialAccountInfoDto){
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}`, body);
        return response.data.value === true;
    };

    async updateInstaPassword(abuserId: string, password: SyncAccountPasswordDto){
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/password`, password);
        return response.status === 200;
    };

    async updateAbuserBan(abuserId: string) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/ban`);
        return response.status === 200;
    };

    async updateAbuserLock(abuserId: string) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/lock`);
        return response.status === 200;
    };

    async updateAbuserUnlock(abuserId: string) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/unlock`);
        return response.status === 200;
    };

    async updateAbuserReauthorization(abuserId: string) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/reauthorization`);
        return response.status === 200;
    };

    async updateSession(abuserId: string, cookie: SyncAccountSessionDto ) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/session`, cookie);
        return response.status === 200;
    };

    async refillOrder(orderTaskId: string, fillCnt: RefillOrderTasksDto) {
        const response = await axiosInstance.post(`${this.url}/orders/refill/order-tasks/${orderTaskId}`, fillCnt);
        return response.status === 201;
    };

    async getDailyFreePromotions(startDate: string, endDate: string): Promise<Array<DashboardDailyPromotionDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/daily-free-promotions?start-date=${startDate}&end-date=${endDate}`);
        return response.data ?? [];
    };

    async updateProcessDailyFreePromotions(body: ProcessDailyFreePromotionsDto) {
        const response = await axiosInstance.post(`${this.url}/dashboard/process-daily-free-promotions`, body);
        return response.status === 201;
    };

    async deleteDailyFreePromotions(orderId: string) {
        const response = await axiosInstance.delete(`${this.url}/dashboard/daily-free-promotions/orders/${orderId}`);
        return response.status === 200;
    };

    async updateMemo(abuserId: string, memo: string ) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/memo`, { memo });
        return response.status === 200;
    };

    async getAccountStatusType() {
        const response = await axiosInstance.get(`${this.url}/dashboard/account-status-types`);
        return response.data ?? [];
    };

    async updateAbuserStatus(abuserId: string, status: string) {
        const response = await axiosInstance.put(`${this.url}/dashboard/set-abuser-status/${abuserId}?status=${status}`);
        return response.status === 200;
    };

    async getInformationLetterLink(orderId: string) {
        const response = await axiosInstance.get(`${this.url}/dashboard/information-letter-link/${orderId}`);
        return response.data ?? '';
    };

    async getAbusersHistory(abuserId: string) {
        const response = await axiosInstance.get(`${this.url}/abusers/${abuserId}/history`);
        return response.data ?? [];
    };

    async searchUser(type: string, term: string): Promise<Array<AbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/search/abusers?type=${type}&term=${term}`);
        return response.data;
    };

    async getAllBilling(targetId: string): Promise<number> {
        const response = await axiosInstance.get(`${this.url}/dashboard/all-billings?targetId=${targetId}`);
        return response.data;
    };

    async searchAbusers(type: string, term: string): Promise<Array<AbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/search/abusers?type=${type}&term=${term}`);
        return response.data;
    };

    async getAsList(startDate: string): Promise<Array<GetBannedAbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/banned-abusers?startDate=${startDate}`);
        return response.data;
    };

    async getAllAsHistory(payload: {
        taskType: string;
        targetId?: string;
        targetUrl?: string;
    }): Promise<Array<AsHistoryDto>> {
        const response = await axiosInstance.post(`${this.url}/dashboard/as-history-count`, payload);
        return response.data;
    };

    async getAsHistory(startDate: string): Promise<Array<AsHistoryMapAsHistoryMap>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/as-histories/v2?startDate=${startDate}`);
        return response.data;
    };

    async addAsHistory(history: CreateAsHistoryDto): Promise<AsHistoryDto> {
        const response = await axiosInstance.post(`${this.url}/dashboard/as-histories`, history);
        return response.data;
    };

    async updateAgingCnt(abuserId: string, agingCnt: number) {
        const response = await axiosInstance.put(`${this.url}/abusers/${abuserId}/aging-cnt`, { agingCnt });
        return response.status === 200;
    };

    async getProxy() {
        const response = await axios.get('https://proxy-manager.among.world/proxy/');
        return response.data ?? undefined;
    };

    async updateProxyGroup(proxyId: string, group: 'production' | 'staging' | 'prober') {
        const response = await axios.patch(`https://proxy-manager.among.world/proxy/${proxyId}`, {
            group: group
        });
        return response.status === 200;
    };

    async postShutdownProxy(proxyId: string) {
        const response = await axios.post(`https://proxy-manager.among.world/proxy/${proxyId}/shutdown`);
        return response.status === 200;
    };

    async healthCheckProxy(host: string, portNumber: number) {
        const response = await axios.get('https://api.staging.among.world/health',
        {
            proxy: {
                protocol: 'http',
                host: host,
                port: portNumber,
                auth: {
                    username: 'insta-leader',
                    password: 'among2020!'
                },
            },
        });
        return response.status === 200;
    };

    async doAs(orderTaskId: string, fillCnt: number) {
        const response = await axiosInstance.post(`${this.url}/dashboard/as`, { orderTaskId, fillCnt });
        return response.data;
    };

    async updateOrder(orderTaskId: string, data: {
        targetId?: string;
        targetUrl?: string;
    }) {
        const response = await axiosInstance.post(`${this.url}/order-tasks/${orderTaskId}`, data);
        return response.data;
    };

    async finishOrderTask(orderTaskId: string) {
        const response = await axiosInstance.post(`${this.url}/order-tasks/${orderTaskId}/done`);
        return response.data;
    };

    async getSubscriptionList(detectedDate: string, status: string): Promise<Array<DashboardSubscriptionDto>> {
        const response = await axiosInstance.get(`${this.url}/subscriptions?detected-date=${detectedDate}&status=${status}`);
        return response.data;
    };

    async getSubscriptionListV2(detectedDate: string, status: string): Promise<Array<DashboardSubscriptionDto>> {
        const response = await axiosInstance.get(`${this.url}/subscriptions/v2?detected-date=${detectedDate}&status=${status}`);
        return response.data;
    };

    async getSubscription(subscriptionId: string){
        const response = await axiosInstance.get(`${this.url}/subscriptions/${subscriptionId}`);
        return response.data;
    };

    async postSubscription(data: CreateSubscriptionDto) {
        const response = await axiosInstance.post(`${this.url}/subscriptions`, data);
        return response.data;
    };

    async getSubscriptionOrderList(): Promise<Array<SubscriptionOrderDto>> {
        const response = await axiosInstance.get(`${this.url}/subscription-orders`);
        return response.data;
    };

    async getPackageList(detectedDate: string, status: string): Promise<Array<DashboardSubscriptionDto>> {
        const response = await axiosInstance.get(`${this.url}/subscription-packages?detected-date=${detectedDate}&status=${status}`);
        return response.data;
    };

    async getQuestPackage(detectedDate: string): Promise<Array<UrgentSubscriptionDto>> {
        const response = await axiosInstance.get(`${this.url}/subscription-packages/urgent-list/${detectedDate}`);
        return response.data;
    };

    async createPackage(data: CreatePackageSubscriptionDto): Promise<Array<SubscriptionDto>> {
        const response = await axiosInstance.post(`${this.url}/subscriptions/packages`, data);
        return response.data;
    };

    async suspendSubscription(subscriptionId: string) {
        const response = await axiosInstance.post(`${this.url}/subscriptions/${subscriptionId}/suspend`);
        return response.data;
    };

    async resumeSubscription(subscriptionId: string) {
        const response = await axiosInstance.post(`${this.url}/subscriptions/${subscriptionId}/resume`);
        return response.data;
    }

    async revokeSubscription(subscriptionId: string) {
        const response = await axiosInstance.post(`${this.url}/subscriptions/${subscriptionId}/revoke`);
        return response.data;
    };

    async useAutoLike(autoLikeCustomerId: string, data: PublishSubscriptionActionDto) {
        const response = await axiosInstance.post(`${this.url}/auto-like/${autoLikeCustomerId}/use`, data);
        return response.data;
    };

    async actionSubscription(subscriptionId: string, data: PublishSubscriptionActionDto) {
        const response = await axiosInstance.post(`${this.url}/subscriptions/${subscriptionId}/actions`, data);
        return response.data;
    };

    async deleteActionSubscription(subscriptionId: string, actionId: string) {
        const response = await axiosInstance.delete(`${this.url}/subscriptions/${subscriptionId}/actions/${actionId}`);
        return response.data;
    };

    async getDetectedItems(subscriptionId: string, date: string) {
        const response = await axiosInstance.get(`${this.url}/subscriptions/${subscriptionId}/detected-items?date=${date}`);
        return response.data;
    };

    async getSubscriptionAction(subscriptionId: string, date: string) {
        const response = await axiosInstance.get(`${this.url}/subscriptions/${subscriptionId}/subscription-actions?date=${date}`);
        return response.data;
    };

    async getQuestSubscription(detectedDate: string): Promise<Array<UrgentSubscriptionDto>> {
        const response = await axiosInstance.get(`${this.url}/subscriptions/urgent-list/${detectedDate}`);
        return response.data;
    };

    async getQuestSubscriptionV2(detectedDate: string): Promise<Array<UrgentSubscriptionDto>> {
        const response = await axiosInstance.get(`${this.url}/subscriptions/v2/urgent-list/${detectedDate}`);
        return response.data;
    };

    async getMagicValues(): Promise<MagicValues> {
        const response = await axiosInstance.get(`${this.url}/dashboard/magic-values`);
        return response.data;
    };

    async updateMagicValues(updateType: MagicValueType, value: string): Promise<number> {
        const response = await axiosInstance.put(`${this.url}/dashboard/magic-values`, { updateType, value });
        return response.data;
    };

    async getSubscriptionMemos(orderPhone: string) {
        const response = await axiosInstance.get(`${this.url}/subscription-memos/?orderPhone=${orderPhone}`);
        return response.data;
    };

    async postSubscriptionMemo(data: CreateSubscriptionMemoDto) {
        const response = await axiosInstance.post(`${this.url}/subscription-memos/?orderPhone`, data);
        return response.data;
    };

    async deleteSubscriptionMemo(memoId: string) {
        const response = await axiosInstance.delete(`${this.url}/subscription-memos/${memoId}`);
        return response.data;
    };

    async editSubscription(subscriptionId: string, data: ChangeSubscriptionDto) {
        const response = await axiosInstance.put(`${this.url}/subscriptions/${subscriptionId}`, data);
        return response.data;
    };

    async updateOrderTaskPriority(orderTaskId: string, priority: OrderTaskPriorityType) {
        return await axiosInstance.put(`${this.url}/order-tasks/${orderTaskId}/priority`, { priority });
    };

    async goodbyeSubscription(subscriptionId: string) {
        const response = await axiosInstance.post(`${this.url}/subscriptions/${subscriptionId}/goodbye`);
        return response.data;
    };

    async postAddOrderMemo(orderId: string, body: CreateOrderMemoDto) {
        const response = await axiosInstance.post(`${this.url}/orders/${orderId}/memo`, body);
        return response.data;
    };

    async getOrderMemo(orderId: string) {
        const response = await axiosInstance.get(`${this.url}/orders/${orderId}/memo`);
        return response.data;
    };

    async getOrderMemos(orderIds: Array<string>) {
        const response = await axiosInstance.get(`${this.url}/orders/order-memos/orders`, { params: orderIds });
        return response.data;
    };

    async putUpdateOrderMemo(orderId: string, memoId: string) {
        const response = await axiosInstance.put(`${this.url}/orders/${orderId}/memo/${memoId}`);
        return response.data;
    };

    async deleteOrderMemo(orderId: string, memoId: string) {
        const response = await axiosInstance.delete(`${this.url}/orders/${orderId}/memo/${memoId}`);
        return response.data;
    };

    async getInActionOrderProgress(): Promise<Array<OrderProgressDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/in-action-order-progresses`);
        return response.data;
    };

    async getCanceledOrderProgress(): Promise<Array<OrderProgressDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/canceled-order-progresses`);
        return response.data;
    };

    async getCompletionOrderProgress(): Promise<Array<OrderProgressDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/completion-order-progresses`);
        return response.data;
    };

    async postGodoMallOrderList(date: string) {
        const response = await axiosInstance.get(`${this.url}/dashboard/godo-mall-orders/${date}`);
        return response.data;
    };

    async postMissingSlackMessage(order: ShopOrderResponse) {
        const response = await axios.post('https://insta-leader-bot.herokuapp.com/order', order);
        return response.data;
    };

    async postMembershipDecrCount(membershipData: TemporaryMembershipDto) {
        const response = await axiosInstance.post(`${this.url}/dashboard/send-membership-decr-count`, membershipData);
        return response.data;
    };

    async postSendMembershipStartAligo(aligoData: SendMembershipStartDto) {
        const response = await axiosInstance.post(`${this.url}/dashboard/send-membership-start`, aligoData);
        return response.data;
    };

    async getDepositsLists(page: number, take: number, isMappedDeposit: boolean): Promise<Array<DepositPageDto>> {
        const response = await axiosInstance.get(`${this.url}/deposits?order=DESC&page=${page}&take=${take}&isMappedDeposit=${isMappedDeposit ? 'true' : 'false'}`);
        return response.data;
    };

    async postDeposit(depositData: CreateDepositDto) {
        const response = await axiosInstance.post(`${this.url}/deposits`, depositData);
        return response.data;
    };

    async deleteDeposit(depositId: string) {
        const response = await axiosInstance.delete(`${this.url}/deposits/${depositId}`);
        return response.data;
    };

    async postResolveDeposit(orderId: string, depositId: string) {
        const response = await axiosInstance.post(`${this.url}/deposits/${depositId}/order/${orderId}`);
        return response.data;
    };

    async postResolveSubscriptionDeposit(subscriptionId: string, depositId: string) {
        const response = await axiosInstance.post(`${this.url}/deposits/${depositId}/subscriptionId/${subscriptionId}`);
        return response.data;
    };

    async getOrderDeposits(orderId: string) {
        const response = await axiosInstance.get(`${this.url}/orders/${orderId}/deposits`);
        return response.data;
    };

    async getCanAutoPilot(): Promise<boolean> {
        const response = await axiosInstance.get(`${this.url}/auto-pilot/status`);
        return response.data;
    };

    async activateAutoPilot() {
        return await axiosInstance.post(`${this.url}/auto-pilot/activate`);
    };

    async deactivateAutoPilot() {
        return await axiosInstance.post(`${this.url}/auto-pilot/deactivate`);
    };

    async getAutopilotParameter(): Promise<AutopilotParameter> {
        const response = await axiosInstance.get(`${this.url}/auto-pilot/parameters`);
        return response.data;
    };

    async updateAutopilotParameter(parameters: AutopilotParameter) {
        return await axiosInstance.post(`${this.url}/auto-pilot/parameters`, parameters);
    };

    async createFlashNotification(content: CreateFlashNotificationDto) {
        const response = await axiosInstance.post(`${this.url}/dashboard/create-flash-notification`, content);
        return response.data;
    };

    async updateFlashNotification(content: UpdateFlashNotificationDto) {
        const response = await axiosInstance.put(`${this.url}/dashboard/update-flash-notification`, content);
        return response.data;
    };

    async getNotifications(): Promise<Array<FlashNotificationDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/flash-notifications`);
        return response.data;
    };

    async getAutoLikeList(): Promise<Array<TempAutoLikeCustomerDto>> {
        const response = await axiosInstance.get(`${this.url}/auto-like`);
        return response.data;
    };

    async createAutoLike(payload: CreateTempAutoLikeCustomerDto) {
        const response = await axiosInstance.post(`${this.url}/auto-like`, payload);
        return response.data;
    };

    async getUrgentAutoLikeSubscriptions(date: string): Promise<Array<UrgentSubscriptionDto>>{
        const response = await axiosInstance.get(`${this.url}/auto-like/urgent-auto-like-subscriptions?detected-date=${date}`);
        return response.data;
    };

    async getAutoLikeSubscriptions(date: string, status: string): Promise<Array<DashboardSubscriptionDto>>{
        const response = await axiosInstance.get(`${this.url}/auto-like/auto-like-subscriptions?detected-date=${date}&status-type=${status}`);
        return response.data;
    };

    async searchAutoLikeCustomerIdSubscriptions(autoLikeCustomerId: string): Promise<Array<SubscriptionDto>> {
        const response = await axiosInstance.get(`${this.url}/auto-like/${autoLikeCustomerId}/subscriptions`);
        return response.data;
    };

    async updateAutoLike(autoLikeCustomerId: string, data: ChangeTempAutoLikeCustomerDto): Promise<TempAutoLikeCustomerDto> {
        const response = await axiosInstance.put(`${this.url}/auto-like/${autoLikeCustomerId}`, data);
        return response.data;
    };

    async getAutoLike(autoLikeCustomerId: string): Promise<TempAutoLikeCustomerDto> {
        const response = await axiosInstance.get(`${this.url}/auto-like/${autoLikeCustomerId}`);
        return response.data;
    };

    async addTargetIdAutoLike(autoLikeCustomerId: string, data: AddNewIgIdDto) {
        const response = await axiosInstance.post(`${this.url}/auto-like/${autoLikeCustomerId}/subscriptions`, data);
        return response.data;
    };

    async extendAutoLike(data: ExtendTempAutoLikeCustomerDto) {
        const response = await axiosInstance.post(`${this.url}/auto-like/extend-membership`, data);
        return response.data;
    };

    async getForeignerOrderProgresses(): Promise<Array<OrderProgressDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/foreign-order-progresses`);
        return response.data;
    };

    async bakeNewBiscuit(payload: {
        key: string;
        value: string;
        ttl: number;
    }) {
        const response = await axiosInstance.post(`${this.url}/biscuit`, payload);
        return response.data;
    };

    async getBiscuit(key: string): Promise<string> {
        const response = await axiosInstance.get(`${this.url}/biscuit/${key}`);
        return response.data;
    };

    async updateProgressCount(orderTaskId: string, data: ChangeOrderTaskProgressCountDto) {
        const response = await axiosInstance.post(`${this.url}/order-tasks/${orderTaskId}/progress-count`, data);
        return response.data;
    };

    async updateOrderReservedDt(orderTaskId: string, data: ChangeReservedDtDto) {
        const response = await axiosInstance.post(`${this.url}/order-tasks/${orderTaskId}/reserved-dt`, data);
        return response.data;
    };

    async getExternalVendors(): Promise<Array<ExternalServiceJobDto>> {
        const response = await axiosInstance.get(`${this.url}/external-vendors`);
        return response.data;
    };

    async cancelExternalVendors(jobId: string) {
        const response = await axiosInstance.delete(`${this.url}/external-vendors/${jobId}`);
        return response.data;
    };

    async getBiscuitFromKey(key: string): Promise<ShopOrderResponse> {
        const response = await axiosInstance.get(`${this.url}/biscuit/${key}`);
        return response.data;
    };

    async createManualPromotion(body: CreateManualPromotionDto) {
        const response = await axiosInstance.post(`${this.url}/dashboard/create-manual-promotion`, body);
        return response.data;
    };

    async createDailyPromotionAbuser(body: CreateDailyPromotionAbuserDto) {
        const response = await axiosInstance.post(`${this.url}/dashboard/daily-promotion-abusers`, body);
        return response.data;
    };

    async getDailyPromotionAbusers(): Promise<Array<DailyPromotionAbuserDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/daily-promotion-abusers`);
        return response.data;
    };

    async deleteDailyPromotion(id: string) {
        const response = await axiosInstance.delete(`${this.url}/dashboard/daily-promotion-abusers/${id}`);
        return response.data;
    };

    async createStaff(body: CreateStaffDto) {
        const response = await axiosInstance.post(`${this.url}/staff`, body);
        return response.data;
    };

    async getStaff(): Promise<StaffDto> {
        const response = await axiosInstance.get(`${this.url}/staff`);
        return response.data;
    };

    async changeStaffPassword(body: ChangeStaffPasswordDto) {
        const response = await axiosInstance.put(`${this.url}/staff/password`, body);
        return response.data;
    };

    async getCustomers(): Promise<Array<CustomerDto>> {
        const response = await axiosInstance.get(`${this.url}/dashboard/customers`);
        return response.data;
    };

    async getCustomerToken(phone: string): Promise<ResponseLoginCustomerDto> {
        const response = await axiosInstance.get(`${this.url}/dashboard/customer-token?phone=${phone}`);
        return response.data;
    };

    async changeCustomerPassword(body: ReassignCustomerPasswordDto) {
        const response = await axiosInstance.post(`${this.url}/dashboard/reassign-customer-password`, body);
        return response.data;
    };

    async updateCustomerInfo(body: UpdateCustomerDto): Promise<CustomerDto> {
        const response = await axiosInstance.post(`${this.url}/dashboard/update-customer`, body);
        return response.data;
    };
};

const repository = new Repository();
export default repository;